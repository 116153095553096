@import "bootstrap/bootstrap";

// Owl Carousel Components
@import "owl/owl.carousel.less";

//@import "bootstrap-select/bootstrap-select.less";

// Font-Awesome
@import "font-awesome/font-awesome.less";

// Custom (non-bootsrap) Project Styles - must be imported last
@import "jquery.fancybox.less";
@import "connectiv/z-index.less";
@import "connectiv/sprite.less";
@import "connectiv/custom-vars.less";
@import "connectiv/conn-basics.less";
@import "connectiv/fonts.less";

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

@brand-height: 60px; //Höhe der Logografik
@navbar-height: 100px;
@brand-height-small: 44px;
@navbar-height-small: 54px;
@brand-padding-vertical: ((@navbar-height - @brand-height) / 2);
@brand-padding-vertical-small: (@navbar-height-small - @brand-height-small) /2;
@button-grey: #d4d7dc;
@text-grey: #474a55;
@background-grey: #e7e7e7;


body {
  background-color: @background-grey;
  color: @text-grey;
  font-size: 14px;
  line-height: 1.3;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  overflow: visible;
  position: relative;
}

.container-fluid {
  max-width: @container-lg;
}

.font-size(@rem) {
  @remValue: @rem;
  @pxValue: (@rem * 10);
  font-size: ~"@{pxValue}px";
  font-size: ~"@{remValue}rem";
}

.fa {
  font-weight: inherit;
}

input {
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  color: rgba(0, 124, 193, 0.5);
}

p {
  line-height: 1.5;
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.4;
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
}

h1 {
  font-size: 20px;
  margin-bottom: 5px;
  color: @brand-primary;
}

h2 {
  font-size: 18px;
  margin-bottom: 23px;
  color: @brand-primary;
}

h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

h4 {
  font-size: 14px;
  margin-bottom: 5px;
}

.btn-primary {
  background-color: #e7e7e7;
  color: #000;
  border: none;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #007cc1;
  border: none;
}

.img_right {
  max-width: 100%;
}

#content {
  ol li {
    content:"";
  }
}

.contentsite{
  background-color: #fff;
  h1, h2 {
    color: @brand-primary;
  }
  h3, h4 {
    color: @text-grey;
  }
}

.container #content table tr td {
  font-size: 12px
}

.container #content table tr th {
  background-color: #007cc1;
  color: #FFF;
}

.text-small{
  font-size: 10px;
}

.img-rounded {
  border-radius: 10px;
}

.navbar-default {
  background-color: #fff;
  z-index: auto;
}

.navbar-collapse {
  border: none;
}

.navbar-brand {
  height: @brand-height + 2*@brand-padding-vertical;
  padding: @brand-padding-vertical 22px;
  max-width: 200px;
  width: 60%;
  float: right;
  &.smaller {
    height: @brand-height-small + 2*@brand-padding-vertical-small;
    padding: @brand-padding-vertical-small 5px;
  }
}

.navbar-brand__img {
  height: 100%;
  display: block;
  img {
    height: 100%;
    float: right;
  }
}

.navbar-open {
  padding: 10px 14px;
  margin-top: 37px;
  margin-bottom: 19px;
  margin-left: 20px;
  float: left;
  height: 44px;
  width: 60px;
  border: 1px solid @button-grey;
  background-color: transparent;
  color: @brand-primary;
  .icon-bar {
    width: 30px;
    height: 4px;
  }
}

.grey-background {
  display: none;
  position: fixed;
  top: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  width: 100%;
  &.smaller {
    top: @navbar-height-small + 5px;
  }
}

.grey-background>.js-nav-close {
  float: right;
  height: 100%;
  width: 100%;
  padding: 240px;
}

.js-nav-close--blau {
  background-color: @brand-primary;
  padding: 10px 20px 5px 15px;
}

.js-nav-close--blau span {
  display: inline-block;
  color: #fff;
  margin-bottom: 5px;
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.js-nav-close--blau:after {
  font-family: 'FontAwesome';
  content: "\f105";
  margin-right: 5px;
  margin-top: 1px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  float: right;
}


.navigation-left {
  padding: 0;
  background-color: @background-grey;
  position: absolute;
  z-index: 1000;
  left: -260px;
  width: 260px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.left-nav {
  background-color: @background-grey;
  padding: 5px 20px 45px 15px;
  margin-bottom: 0;
  list-style: none;
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  position: absolute;
  z-index: 1000;
  width: 260px;
  left: 0;
  height: auto;
  top: 95px;
  bottom: 0;
}

.left-nav__search {
  position: relative;
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 20px;
  &::after {
    font-family: 'FontAwesome';
    content: '\f002';
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    font-size: 16px;
    color: rgba(0, 124, 193, 0.5);
  }
}

.left-nav__input {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px 30px 10px 10px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

.left-nav__input::-webkit-input-placeholder {
  font-size: 16px;
}
.left-nav__input:-moz-placeholder {
  font-size: 16px;
}
.left-nav__input::-moz-placeholder {
  font-size: 16px;
}
.left-nav__input:-ms-input-placeholder {
  font-size: 16px;
}

.left-nav__back {
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  &::before {
    font-family: 'FontAwesome';
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
}

.left-nav__current-open a {
  color: @brand-primary !important;
  margin-bottom: 10px;
  font-size: 18px;
}

.left-nav__dropdown a {
  padding: 10px 15px 10px 0;
  color: @text-grey;
  position: relative;
  cursor: pointer;
  display: block;
  &:hover,
  &:focus {
    color: @brand-primary;
    text-decoration: none;
  }
}

.left-nav__dropdown {
  border-bottom: 1px solid @button-grey;
  padding: 0;
  &>a::after {
    font-family: 'FontAwesome';
    content: "\f105";
    color: @text-grey;
    position: absolute;
    top: 10px;
    right: 5px;
  }
  &>ul {
    display: none;
    left: -260px;
    top: 0;
  }
}

.left-nav__dropdown--end{
  &>a::after {
    display: none;
  }
}

.left-nav__dropdown-menu {
  display: none;
  list-style: none;
  padding: 0 0 0 13px;
  &>li>a {
    font-family: 'Hybrea W00 Light',Arial,Helvetica,sans-serif;
    padding: 8px 0;
  }
}

.dropdown-menu-info img {
  width: 100%;
}

.box {
  background-color: #fff;
  margin: 30px 5px 0;
  padding: 3px;
  font-size: 14px;
  a, h2 {
    color: @text-grey;
  }
  h2 {
    margin-bottom: 13px;
  }
}

.box--presse {
  padding-top: 10px;
  a:hover {
    h2 {
      color: @brand-primary;
    }
  }
  h2 {
    margin-left: 13px;
    margin-bottom: 10px;
  }
  .owl-controls {
    margin-top: 0;
    .owl-dots {
      background-color: transparent;
      margin: 0 -3px -3px -3px;
      height: 45px;
      padding: 10px;
      span {
        width: 14px !important;
        height: 14px !important;
        background-color: #898d96 !important;
      }
      .active span{
        background-color: #63666d !important;
      }
    }
  }
  .box__inhalt {
    background-color: #f4f4f4;
    padding: 10px 8px;
    margin-bottom: 3px;
  }
}

.box__newstext {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  a {
    margin: -5px 0px -10px;
    padding: 5px 40px 10px 5px;
    .text-small {
      color: @text-grey;
      margin-bottom: 8px;
    }
  }
}

.owl-stage {

  .owl-item {
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
  }
}

.owl-stage .owl-item:last-of-type {
  margin-right: 0 !important;
}

.navigator {
  font-size: 36px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
}

.box__inhalt {
  padding: 12px 18px 22px;
  display: inline-block;
  width: 100%;
  a {
    display: block;
    position: relative;
    text-decoration: none;
    &:hover h2 {
      color: @brand-primary;
    }

  }
}

.box__button {
  height: 45px;
  background-color: @button-grey;
  text-align: center;
  margin: -3px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 700;
  a {
    color: @text-grey;
    padding: 12px 0;
    display: block;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  span {
    margin-left: 5px;
    font-weight: 700;
  }
  &:hover {
    background-color: @brand-primary;
    color: #fff;
    a, h4 {
      color: #fff;
    }
  }
}

.box__button h4 {
  margin-top: 0 !important;
}

.box__img {
  text-align: center;
  width: 100%;
  overflow: hidden;
}

.box--reihe3 {
  margin-bottom: 18px;
  .box__inhalt {
    padding: 10px 13px;
  }
}

.blue-box__row {
  margin-left: -7px;
  margin-right: -7px;
}

.blue-box__outer {
  text-align: center;
  color: #fff;
  margin: 7px auto;
  padding: 0 7px;
  float: left;
  width: 50%;
}

.blue-box__title {
  color: #fff;
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.blue-box {
  background-color: @brand-primary;
  padding: 10px 5px;
  display: block!important;
  width: 100%;
  text-align: center;
  &:hover,
  &:active {
    text-decoration: none;
    cursor: pointer;
  }
}

.blue-box__img {
  height: 40px;
  margin: 17px 0;
  img {
    height: 100%;
    width: auto;
    margin: 0 auto;
  }
}

.box__up {
  background-color: #50535a;
  width: 44px;
  height: 44px;
  border: 1px solid #fff;
  text-align: center;
  font-size: 30px;
  margin: 20px 0 18px;
  a {
    color: #fdfdfd;
  }
}

.content-start {
  background-color: transparent!important;
  font-size: 14px;
}

.content-container {
  margin-top: 30px;
  padding: 0 15px;
  background-color: #fff;
}

.content__textbox {
  padding-top: 15px;
}

.content__img {
  float: right;
  width: 100%;
  margin: 0;
}

.presse_date {
  display: block;
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: 12px;
}

.img-gallery-width {
  //width: 100%; Bitte nicht
}

.img-responsive {
  //width: 100%; SO ein Quatsch
}

/*#content {
  ul li {
    padding: 0 0 0 10px;
    &::before {
      background-color: transparent;
      content: '\f0da';
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
      margin-left: -10px;
      top: 2px;
    }
  }
}*/
#content ol li::before {
  background: none;
}

.content__jobmesse {
  margin-top: 40px;
  margin-bottom: 50px;
  background-color: #cacdd2;
  .row {
    background-color: #cacdd2;
    height: 64px;
    .jobmesse__img {
      height: 64px;
      img {
        padding: 0 15px;
        max-width: 100%;
        position: absolute;
        transform: translate(0,-50%);
        top: 50%;
      }
    }
    .jobmesse__text {
      height: 100%;
      h4 {
        color: @text-grey;
        text-align: center;
        font-size: 12px;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
      }
    }
  }
}

.content__video {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.content__link {
  margin: 10px 0 25px !important;
  a {
    margin: 8px 0 0 !important;
    font-weight: 700;
    &::before {
      content: '\f0da';
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0);
      margin-right: 10px;
    }
  }
}

.content__liste {
  margin: 20px 0 50px 10px !important;
}

.content__button {
  height: 30px;
}

.form {
  margin-bottom: 60px;
}

.content__button--blue {
  background-color: @brand-primary;
  color: #fff;
  width: 125px;
  border: none;
  text-align: left;
  border-left: 10px solid darken(@brand-primary, 10%);
  font-size: 18px;
}

.content__button--icon {
  margin-left: 25px;
  width: 35px;
  background-color: @background-grey;
  border: 1px solid @button-grey;
  span {
    color: @brand-primary;
    font-size: 18px;
  }
}

.content__input {
  height: 30px;
  margin: 10px 25px 10px 15px;
  background-color: @background-grey;
  border: 1ps solid @button-grey;
}

.content__checkbox {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 32px !important;
}

.content__button--big {
  height: 150px;
  width: 100%;
  border: none;
  text-align: center;
  font-size: 55px;
  margin: 5px 0;
  span {
    margin: 47px 0;
  }
}

.region__map:hover, .region__map:active {
  fill: #005b8e;
}

//BUS
#tsaid_SuchgruppeGebiet_01 .tsaid_QueryElementSelectPublicServiceTypeCategoryWrapper label {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  margin: 35px 0 10px!important;
}

#tsaid_SuchgruppeGebiet_01 .tsaid_QueryElementSearchFieldAreaWrapper label {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  margin: 30px 0 10px!important;
}

#tsaid_p012300,
#tsaid_p010201,
#tsaid_p170201 {
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  height: 34px;
  max-width: 505px!important;
  border: 1px solid #ccc!important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 0;
}

#tsaid_SuchgruppeGebiet_01 .tsaid_searchButton,
input.tsaid_searchButton {
  background-color: #007cc1!important;
  color: #fff!important;
  font-size: 14px!important;
  max-width: 505px!important;
  padding: 9px!important;
  margin-top: 30px!important;
}

#tsaid_SuchgruppeGebiet_01 .tsaid_searchButton:hover {
  background-color: #005b8e!important;
}

#tsaid_NavLeistungAZ_01 li {
  font-size: 14px!important;
}

#tsaid_AnsichtLeistungkat_01 {
  margin-top: 30px!important;
}

#tsaid_AnsichtLeistungkat_01 .tsaid_description p {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
}

.tsaid_TITLE {
  font-size: 14px!important;
  padding: 8px 10px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
}

#tsaid_Suchgruppe_03 .tsaid_QueryElementSearchFieldFormWrapper label {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  margin: 35px 0 10px!important;
}

#tsaid_p020900 {
  height: 34px;
  max-width: 505px!important;
  padding: 6px 12px !important;
  font-size: 14px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

#suche_tsaid_Suchgruppe_03 .tsaid_searchButton {
  background-color: #007cc1!important;
  color: #fff!important;
  font-size: 14px!important;
  max-width: 505px!important;
  padding: 9px!important;
  margin-top: 5px!important;
}

#suche_tsaid_Suchgruppe_03 .tsaid_searchButton:hover {
  background-color: #005b8e !important;
}

#tsaid_Suchgruppe_01 .tsaid_QueryElementSelectPublicServiceTypeCategoryWrapper label {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  margin: 35px 0 10px!important;
}

#tsaid_p032300 {
  height: 34px;
  max-width: 505px!important;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  padding:6px 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

#suche_tsaid_Suchgruppe_01 .tsaid_searchButton {
  background-color: #007cc1!important;
  color: #fff!important;
  font-size: 14px!important;
  max-width: 505px!important;
  padding: 9px!important;
  margin-top: 5px!important;
}

#suche_tsaid_Suchgruppe_01 .tsaid_searchButton:hover {
  background-color: #005b8e !important;
}

#tsaid_ListeFormular_02 .tsaid_nomatchDescription {
  margin-top: 30px!important;
}

.ytsa_datalist {
  margin-top: 35px!important;
}

.ytsa_datalist .tsaid_NAME,
.ytsa_datalist .tsaid_PAGECOUNT,
.ytsa_datalist .tsaid_DOWNLOAD {
  padding: 8px 10px!important;
  vertical-align: middle;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
}

#tsaid_ListeFormular_02 .ytsa_datalist .tsaid_DOWNLOAD {
  width: 115px!important;
  padding: 8px 10px!important;
  vertical-align: middle;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN p span {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  color: #474a55!important;
  line-height: 1.26!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN ul {
  margin: 17px 0!important;
}

.ytsa_page .tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN ul ul {
  margin: 0 0 0 15px!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN ul li:before {
  content: '\f0da'!important;
  color: #474a55!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN ul li span {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  color: #474a55!important;
  line-height: 1.26!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN ul li {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  color: #474a55!important;
  line-height: 1.26!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN p {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  color: #474a55!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_MAIN div {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  color: #474a55!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_ADDITION p a {
  font-size: 14px!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
  color: #007cc1!important;
  text-decoration: none!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_DESCRIPTION .tsaid_ADDITION p a:hover {
  color: #004b75!important;
}

.tsaid_QueryElementSingleViewPublicServiceType h4 {
  margin-top: 30px!important;
}

#tsaid_ListeOE_01 {
  margin-top: 30px!important;
}

.ytsa_datalist .tsaid_nomatchDescription {
  margin-top: 20px!important;
}

.tsaid_QueryElementCommonNavigation .tsaid_backLink {
  background-color: #007cc1!important;
  color: #fff!important;
  font-size: 14px!important;
}

.tsaid_QueryElementCommonNavigation .tsaid_backLink:hover {
  background-color: #005b8e!important;
}

*.ytsa_page,
.ytsa_page *,
*.tsaid_QueryElementListPublicServiceType *.tsaid_pageDescription,
*.tsaid_QueryElementNavigationAZPublicServiceType ul li,
*.tsaid_QueryElementListPublicServiceType *.tsaid_pager {
  font-family: 'Open Sans',Arial,Helvetica,sans-serif!important;
}

//new

.tsaid_resetButton{
  display: block!important;
  background-color: #007cc1!important;
  color: #fff!important;
  font-size: 14px!important;
  max-width: 505px!important;
  padding: 9px!important;
  margin-top: 30px!important;
  float: right !important;
  border:0px !important;
}

#tsaid_Suchgruppe_01 .tsaid_resetButton,
#tsaid_Suchgruppe_03 .tsaid_resetButton{
  margin-top: 5px!important;
}

.tsaid_QueryElementSearchGroupArea .tsaid_searchButton, .tsaid_QueryElementSearchGroup .tsaid_searchButton, .tsaid_QueryElementSearchFormEvent .tsaid_searchButton, .ytsa_page form .tsaid_searchButton{
  position: relative;
  left: 6px;
  margin-right: 0!important;
  margin-left:0px !important;
}

.tsaid_LayoutSpalte_03 .ytsa_c50l, .tsaid_LayoutSpalte_03 .ytsa_c50r,
.tsaid_LayoutSpalte_04 .ytsa_c50l, .tsaid_LayoutSpalte_04 .ytsa_c50r,
.tsaid_LayoutSpalte_05 .ytsa_c50l, .tsaid_LayoutSpalte_05 .ytsa_c50r,
.tsaid_LayoutSpalte_06 .ytsa_c50l, .tsaid_LayoutSpalte_06 .ytsa_c50r,
.tsaid_LayoutSpalte_07 .ytsa_c50l, .tsaid_LayoutSpalte_07 .ytsa_c50r,
.tsaid_LayoutSpalte_10 .ytsa_c50l, .tsaid_LayoutSpalte_10 .ytsa_c50r,
.tsaid_LayoutSpalte_11 .ytsa_c50l, .tsaid_LayoutSpalte_11 .ytsa_c50r,
.tsaid_LayoutSpalte_13 .ytsa_c50l, .tsaid_LayoutSpalte_13 .ytsa_c50r{
  width: 100%!important;
}
.tsaid_LayoutSpalte_04 .ytsa_c50r .ytsa_subcr,
.tsaid_LayoutSpalte_05 .ytsa_c50r .ytsa_subcr,
.tsaid_LayoutSpalte_06 .ytsa_c50r .ytsa_subcr,
.tsaid_LayoutSpalte_07 .ytsa_c50r .ytsa_subcr,
.tsaid_LayoutSpalte_10 .ytsa_c50r .ytsa_subcr,
.tsaid_LayoutSpalte_11 .ytsa_c50r .ytsa_subcr,
.tsaid_LayoutSpalte_13 .ytsa_c50r .ytsa_subcr {
  padding: 0;
}

.tsaid_resetButton{
  float: left!important;
  margin-right: 21px!important;
}

.ytsa_page .ytsa_c80r .ytsa_subcl div#tsaid_NavLeistungskat_02 .tsaid_level1 a:hover {
  background: initial!important;
  color: #000!important;
}

.tsaid_LayoutSpalte_03 .ytsa_c50r ul, #tsaid_NavLeistungskat_02 ul {
  margin-top: 25px!important;
}

.tsaid_LayoutSpalte_03 .ytsa_c50r ul a {
  margin-right: -2px!important;
}

.tsaid_LayoutSpalte_07 .tsaid_TextAbsatz_03 .tsaid_QueryElementTextP {
  display: none!important;
}

.tsaid_QueryElementSearchFieldAreaWrapper .tsaid_QueryElementSearchFieldArea {
  height: 26px!important;
}

//Ausklappbare Menues

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_TITLE {
  padding-left: 0!important;
}

.tsaid_LayoutSpalte_09 .ytsa_c50l {
  width: 100%!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_expanded, .tsaid_QueryElementSingleViewPublicServiceType .tsaid_collapsed {
  background-repeat: no-repeat!important;
}


.tsaid_LayoutSpalte_05 .tsaid_QueryElementSingleViewPublicServiceType .tsaid_contentNameImage,
.tsaid_LayoutSpalte_10 .tsaid_QueryElementSingleViewPublicServiceType .tsaid_contentNameImage,
.tsaid_LayoutSpalte_09 .tsaid_QueryElementSingleViewPublicServiceType .tsaid_contentNameImage {
  border: none!important;
  width: 16px!important;
}

.tsaid_QueryElementSingleViewPublicServiceType h4 {
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.tsaid_QueryElementSingleViewPublicServiceType h4:focus {
  outline: none!important;
}

.tsaid_QueryElementSingleViewPublicServiceType .tsaid_data, *.tsaid_QueryElementSingleViewPublicServiceType *.tsaid_data * {
  width: 100%!important;
}

.tsaid_QueryElementSearchFieldAreaWrapper .tsaid_validationMessage,
.tsaid_QueryElementSelectAreaWrapper .tsaid_validationMessage,
.tsaid_QueryElementSearchFieldPublicServiceTypeWrapper .tsaid_validationMessage {
  margin-left: 0!important;
}

.tsaid_OrganisationalUnitData .tsaid_label {
  float: left;
  width: 50%!important;
}

.tsaid_OrganisationalUnitData .tsaid_data {
  float: right;
  width: 50%!important;
}

.tsaid_OrganisationalUnitData > div {
  clear: both;
}

//BUS end

.row__xs--stretch {
  display: flex;
  .col--stretch {
    display: flex;
  }
}

.footer {
  background-color: #656871;
  color: #fdfdfd;
}

.footer__logos {
  padding-top: 55px;
}

.footer__img {
  display: block;
  float: left;
  transform: translate(0,-50%);
  &:first-child {
    margin-right: 6%;
  }
  img {
    width: 100%;
  }
}

.footer__nav {
  padding: 16px 5px;
}

.footer__link {
  background-color: #50535a;
  height: 40px;
  margin-bottom: 10px;
  margin-left: -2px;
  margin-right: -2px;
  text-align: left;
  font-size: 12px;
  position: relative;
  a {
    color: #fdfdfd;
    padding: 0 5px;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
  }
}

.footer__social {
  padding-top: 20px;
  padding-bottom: 8px;
  .footer__link {
    margin-bottom: 0px;
    a {
      width: 100%;
      position: relative;
      transform: none;
      top: auto;
      font-size: 20px;
      .center {
        display: inline-block;
      }
      span {
        display: table-cell;
        vertical-align: middle;
        height: 40px;
        &.fa-sign {
          position: relative;
          margin: 0 auto;
          &::before {
            font-size: 20px;
            font-family: 'FontAwesome';
            border-right: 1px solid #fdfdfd;
            margin-right: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.footer__link--fb {
  background-color: #3c5b98;
  .fa-sign::before {
    content: '\f09a';
  }
}

.footer__link--twitter {
  background-color: #00abf2;
  .fa-sign::before {
    content: '\f099';
  }
}

.footer__copyright {
  background-color: #50535a;
  font-size: 10px;
  padding: 14px 0px;
  height: 38px;
  text-align: center;
}

.ui-datepicker {
  border-radius: 0!important;
  border: 1px solid #a1c0d5!important;
}

.ui-datepicker-header {
  border-radius: 0!important;
  background: none!important;
  background-color: #a1c0d5!important;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
}

.ui-datepicker-calendar {
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
}

.ui-datepicker-calendar .ui-state-default {
  background: none!important;
  background-color: #FFF!important;
  color: #007cc1!important;
  border: 1px solid #a1c0d5!important;
}

.ui-datepicker-calendar .ui-state-hover {
  background: none!important;
  background-color: #a1c0d5!important;
  color: #007cc1!important;
  border: 1px solid #007cc1!important;
}

.ui-datepicker-calendar .ui-state-active {
  background-color: #007cc1!important;
  color: #FFF!important;
  border: 1px solid #007cc1!important;
}

.ui-datepicker-next:hover, .ui-datepicker-prev:hover {
  cursor: pointer;
}

.ui-datepicker-next-hover {
  border-radius: 0px!important;
  border: none!important;
  top: 2px!important;
  right: 2px!important;
  background: none!important;
  background-color: #a1c0d5!important;
}

.ui-datepicker-prev-hover {
  border-radius: 0px !important;
  border: none !important;
  top: 2px !important;
  left: 2px !important;
  background: none!important;
  background-color: #a1c0d5 !important;
}

.ui-datepicker .ui-icon {
  height: 24px;
  margin-top: -12px!important;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-w {
  background: none;
  text-indent: 0px;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-w:before {
  position: absolute;
  font-family: FontAwesome;
  font-size: 20px;
  content:"\f053";
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #007cc1;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-e {
  background: none;
  text-indent: 0px;
}

.ui-datepicker .ui-icon.ui-icon-circle-triangle-e:before {
  position: absolute;
  font-family: FontAwesome;
  font-size: 20px;
  content:"\f054";
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #007cc1;
}

.va-kalender input {
  padding: 0 5px;
  color: #007cc1;
}

#schulausfall-outer {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
  background-color: rgba(0,0,0,0.7);
}

#schulausfall {
  font-family: Arial;
  position: relative;
  width: 300px;
  height: 275px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border: 1px #ccc solid;
  background-image: url('../images/schulausfall2.jpg');
  background-position: 0 8px;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 9999;
}

#standalone #schulausfall {
  margin-top: 0;
  top: 5%;
}

#schulausfall_text {
  font-family: Arial;
  color: #fff;
  font-size: 18px;
  height: 240px;
  padding: 80px 20px 0 20px;
  line-height: 24px;
}

#schulausfall img {
  margin-bottom: 10px;
}

td, th {
  padding: auto;
}

#kindertagespflegegeldrechner {
  background-color: @background-grey;
  padding: 10px;
  position: relative;
}

#kindertagespflegegeld, #elternbeitragSumme {
  font-weight: bold;
  background-color: @background-grey;
}

@media (max-width: @screen-xs-max) {
  .image-limiter {
    max-height: 100%!important;
  }
}

.nlregform {
  margin-top: 30px;
}

.nlregform input[type="text"], .nlregform select {
  width: 100%;
  height: 30px;
  margin: 5px 0 20px 0;
  padding-left: 5px;
  color: #474a55;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
}

.nlregform input[type="radio"] {
  float: left;
  position: relative;
  margin: 0 10px 0 0;
}

.nlregform input[type="checkbox"] {
  position: relative;
  top: 2px;
  margin-bottom: 10px;
}

.service-images {
  margin-bottom: 35px;
}

.service-detail__slider {
  display: flex;
  margin: 0 -12px;
}

.service-detail__img {
  flex-basis: 0;
  flex-grow: 1;
  overflow: hidden;
  margin: 0 12px;
  display: flex;
  justify-content: center;
}

.service-detail__img img {
  min-height: 100%;
}

.service-detail-box {
  padding: 15px;
  background-color: #f2f2f2;
}

.service-detail__section {
  width: 100%;
  padding: 10px 0px;
}

.service-detail__section--top {
  color: @brand-primary;
  font-size: 20px;
  font-family: 'Hybrea W00 Light', Arial, Helvetica, sans-serif;
}

.service-detail__field-title {
  font-weight: bold;
}

.service-detail__options {
  margin: 10px 0 0 0;
}

.service-detail__options a {
  padding: 0 10px;
  text-decoration: none;
}

.service-list__top {
  padding: 10px 0;
}

.service-list__amount {
  font-weight: bold;
  font-size: 16px;
}

.service-list__view a {
  color: #c9c9c9;
  font-size: 16px;
  text-decoration: none;
}

.service-list__view a:not(:first-of-type) {
  margin-left: 25px;
}

.service-list__view a.active {
  color: @brand-primary;
}

.service-list__item {
  background-color: #f2f2f2;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
}

.service-list__img {
  overflow: hidden;
  height: 100%;
  flex: 1 100%;
}

.service-list__img img {
  height: 100%;
  margin-left: 50%;
  transform: translate(-50%,0);
  width: 100%;
}

.service-list__item-info {
  height: 100%;
  padding: 15px;
  position: relative;
  flex: 1 0 0;
}

.service-list__item-info .rememberlist-setter {
  position: absolute;
  right: 15px;
}

a.service-list__item-name {
  display: block;
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: @brand-primary;
  margin-bottom: 10px;
}

.service-list__item-section {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  margin-bottom: 6px;
}

.service-list__item-title {
  width: 140px;
  font-weight: bold;
  flex: 1 100%;
}

.service-list__item-text {
  flex: 3 0 0;
}

.service-list__item-links {
  float: right;
}

.service-list__item-links a {
  text-decoration: none;
}

.service-list__item-links a:not(:first-of-type) {
  margin-left: 10px;
}

.grey-box {
  padding: 15px;
  background: #F2F2F2;
  box-shadow: 0px 3px 6px #00000029;
}

.create-service .form-group {
  margin-bottom: 10px;
}

.create-service .inline-form-group {
  margin-bottom: 10px;
}

.create-service .form-control {
  border: none;
}

.create-service .form-subhead {
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
}

.create-service .file-upload .cell.cell-label {
  vertical-align: middle;
}

.create-service .file-upload .cell.cell-label label {
  font-weight: 500;
}

.create-service textarea {
  resize: vertical;
  min-height: 34px;
}

.create-service input,
.create-service .input-file-field {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.create-service input[type="file"].input-file {
  display: none;
}

.create-service .cell.cell-label a:focus {
  outline: none;
}

.create-service .cell-chars .char_num {
  color: #474A55;
  font-size: 12px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.create-service .popover {
  max-width: 200px;
}

.create-service .popover .popover-title {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.create-service button.btn.btn-send {
  background-color: @brand-primary;
  color: #fff;
  padding: 7px 40px;
  outline: none;
  margin-top: 20px;
  float: right;
  border: none;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}


.create-service button.btn.btn-send:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.service .modal-content {
  border-radius: 3px;
}

.service .modal label {
  font-weight: 500;
}

.service .modal .modal-title {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.create-service .cell.cell-upload-btn button {
  background-color: @brand-primary;
  color: #fff;
}

.create-service .cell-select button {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.create-service .cell-select button:after {
  content: '\f107';
  font-family: FontAwesome;
  position: absolute;
  right: 5px;
  top: 7px;
}

.multiple-select {
  position: relative;
}

.multiple-select-btn {
  padding-right: 18px;
  text-align: left;
}

.multiple-select-btn:after {
  content: '\f107';
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
  top: 7px;
}

.multiple-select-menu {
  display: none;
  position: absolute;
  background: #fff;
  width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.multiple-select-menu .options {
  padding: 5px 16px 5px 10px;
  border: 1px solid #E7E7E7;
  border-top: 0;
  max-height: 170px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.multiple-select-menu.open {
  display: block;
}

.multiple-select-menu .options .checkbox.disabled {
  color: rgba(0, 0, 0, 0.4);
}

.multiple-select-set-btn {
  background: @brand-primary;
  color: #ffffff;
  text-transform: uppercase;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.service-filter {
  margin-bottom: 20px;
}

.service-filter__header {
  padding: 5px 0;
  color: @brand-primary;
}

.service-filter__title {
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.service-filter__links a {
  text-decoration: none;
}

.service-filter__links a:first-of-type {
  margin-right: 10px;
}

.service-filter__select {
  margin: 10px 0;
}

.service-filter .multiple-select-btn {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E7E7E7;
  border-radius: 2px;
}

.service-filter .multiple-select-btn:after {
  content: '\f0d7';
  color: @brand-primary;
  right: 10px;
}

.service-filter__selectedrow {
  margin: 0 -4px;
}

.btn_del_filter {
  position: relative;
  background-color: @brand-primary;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  border: none;
  padding: 5px 30px 5px 10px;
  outline: none;
  margin: 4px;
}

.btn_del_filter_all {
  position: relative;
  background-color: #fff;
  border: 1px solid #ff0000;
  color: #ff0000;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  padding: 5px 10px 5px 10px;
  outline: none;
  margin-left: 7px;
}

.btn_del_filter::before {
  content: '';
  display: block;
  background-color: #fff;
  height: 14px;
  width: 1px;
  top: 7px;
  right: 15px;
  position: absolute;
  transform: rotate(45deg);
}
.btn_del_filter::after {
  content: '';
  display: block;
  background-color: #fff;
  height: 14px;
  width: 1px;
  top: 7px;
  right: 15px;
  position: absolute;
  transform: rotate(-45deg);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 117px;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

.infowindow-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(-50%, -100%);
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
}

.infowindow-container img {
  width: 100%;
  margin-bottom: 5px;
}

.infowindow__head {
  font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: @brand-primary;
  display: block;
  margin-bottom: 5px;
}

.infowindow__descr {
  display: block;
  margin-bottom: 15px;
}

.infowindow__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infowindow__links a {
  text-decoration: none;
}

.infowindow__close {
  position: absolute;
  display: block;
  background-color: @brand-primary;
  height: 16px;
  width: 16px;
  top: -8px;
  right: -8px;
  border-radius: 100%;
  cursor: pointer;
}

.infowindow__close::before {
  content: '';
  display: block;
  background-color: #fff;
  height: 10px;
  width: 1px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
}
.infowindow__close::after {
  content: '';
  display: block;
  background-color: #fff;
  height: 10px;
  width: 1px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%) rotate(-45deg);
}

[class*=" icon-"], [class^=icon-] {
  position: relative;
  top: 3px;
}

[class*=" icon-"]:after, [class^=icon-]:after {
  content: '';
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.icon-flag:after {
  background-image: url("../img/Icons/maps-and-flags.svg");
  height: 17px;
  width: 12px;
}

.icon-flag-grey:after {
  background-image: url("../img/Icons/maps-and-flags-grey.svg");
  height: 17px;
  width: 12px;
}

.icon-print:after {
  background-image: url("../img/Icons/printing.svg");
  height: 20px;
  width: 22px;
}

.icon-pdf:after {
  background-image: url("../img/Icons/pdf.svg");
  height: 20px;
  width: 15px;
}

.icon-star:after {
  background-image: url("../img/Icons/star.svg");
  height: 20px;
  width: 20px;
}

.icon-star-full:after {
  background-image: url("../img/Icons/star_fullblue.svg");
  height: 20px;
  width: 20px;
}

.icon-star-grey:after {
  background-image: url("../img/Icons/star_grey.svg");
  height: 20px;
  width: 20px;
}

.icon-list:after {
  background-image: url("../img/Icons/list.svg");
  height: 18px;
  width: 22px;
}

.icon-list-grey:after {
  background-image: url("../img/Icons/list-grey.svg");
  height: 18px;
  width: 22px;
}

.icon-info:after {
  background-image: url("../img/Icons/info.svg");
  height: 16px;
  width: 16px;
}

.icon-more:after {
  background-image: url("../img/Icons/more.svg");
  height: 16px;
  width: 16px;
}


/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: @screen-tablet) {

  @brand-height: 50px;
  @brand-background-height: 70px;
  @brand-height-small: 40px;
  @brand-background-height-small: 50px;
  @brand-padding-vertical: (@brand-background-height - @brand-height) /2;
  @brand-padding-vertical-small: (@brand-background-height-small - @brand-height-small) /2;

  body, .box {
    font-size: 12px;
  }

  h1 {
    font-size: 19px;
    margin-bottom: 5px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 14px;
  }

  .text-small {
    font-size: 11px;
  }

  .text-large {
    font-size: 14px;
  }

  .navbar-default {
    height: 270px;
    background-image: url("../../img/startseite_teaser/header_background.jpg");
    background-size: cover;
    background-position: center;
    border-bottom: 3px solid #fff;
  }

  .contentsite .navbar-default {
    background-color: #a1c0d5;
    background-image: none;
    height: 145px;
  }

  .contentsite .grey-background {
    top: auto;
  }

  .contentsite .global {
    background-color: @brand-primary;
    &>a,
    .global__button::after {
      color: #fff;
    }
    .global__border {
      border-color: #fff;
    }
  }

  .global {
    background-color: #fff;
    display: flex;
    height: 30px;
    font-size: 12px;
    a {
      padding: 7px 10px;
      margin: 0 2px;
      width: auto;
      .fa {
        font-size: 16px;
      }
    }
  }

  .global a.global__link {
    margin: 0 2px;
  }

  .global__search .form-group {
    position: relative;
    padding: 3px 2px;
    height: 30px;
  }

  .global__input {
    //display: none;
    height: 19px;
    //width: 260px;
    width: 132px;
    margin-top: 3px;
    float: left;
    margin-left: 5px;
    padding: 4px;
    border: 1px solid #efefef;
    text-transform: uppercase;
    font-weight: normal;
  }

  .global__button {
    background-color: transparent;
    border: none;
    display: inline-block;
    width: 34px;
    &::after {
      font-family: 'FontAwesome';
      content: '\f002';
      font-size: 16px;
      color: @brand-primary;
      padding: 3px 7px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .global__button--submit {
    //display: none;
  }

  .global__button--prov {
    height: 30px;
    text-align: center;
    padding: 1px 6px;
  }

  .global__border {
    height: 12px;
    border: 1px solid @brand-primary;
    margin: 9px 11px;
  }

  .navbar__background--img {
    position: absolute;
    z-index: 1000;
    margin-top: 19px;
    width: 100%;
    height: @brand-background-height;
    &.smaller {
      position: fixed;
      top: 0;
      height: @brand-background-height-small + 10px !important;
      border-bottom: 10px solid #fff;
      margin-top: 0;
      background-image: url("..\img\startseite_teaser\header_background.jpg");
      background-position: bottom;
      background-size: cover;
    }
  }

  .contentsite .navbar__background--img.smaller {
    background-color: #a1c0d5;
    background-image: none;
  }

  .navbar__background--white {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
  }

  .navbar-header {
    float: none;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;
  }

  .navbar-brand {
    position: relative;
    width: 173px;
    height: @brand-background-height;
    padding: 0 15px 0 0;
    margin-left: 0;
    float: left;
    max-width: none;
    &.smaller {
      height: @brand-background-height-small;
      padding: 0 15px 0 0;
      .navbar-brand__img {
        padding: 0 10px;
        border-bottom: 3px @brand-primary solid;
        img{
          height: @brand-height-small;
        }
      }
    }
  }

  .navbar-brand__img {
    padding: @brand-padding-vertical 10px;
    background-color: #fff;
    img {
      height: 45px;
      float: left;
    }
    &:hover {
      background-color: #fff !important;
    }
  }

  .navbar-brand__white {
    position: absolute;
    display: block;
    height: 100%;
    right: 173px;
    width: 230px;
    background-color: #fff;
  }

  .navbar-brand.smaller .navbar-brand__white {
    border-bottom: 3px @brand-primary solid;
  }

  .nav-top {
    width: 547px;
    text-align: right;
    overflow: hidden;
    &>.nav-top__link>.active {
      background-color: #fff !important;
      color: @brand-primary !important;
      border-bottom: 3px solid @brand-primary;
    }
  }

  .nav-top__text-size {
    font-size: 13px;
    font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
    /*text-transform: uppercase;*/
  }

  .nav-top>.nav-top__link {
    height: @brand-background-height;
    position: inherit;
    width: 109px;
    margin-left: 15px;
    display: inline-block;
    text-align: left;
    float: none;
    &.smaller {
      height: @brand-background-height-small;
      float: left;
      &>a {
        padding: 5px 15px;
      }
    }
  }

  .nav-top>.nav-top__link>a {
    height: 100%;
    background-color: @brand-primary;
    color: #fff !important;
    padding: 15px;
    cursor: pointer;
    &:hover,
    &:active {
      background-color: #fff !important;
      color: @brand-primary !important;
    }
    &:focus {
      background-color: @brand-primary !important;
      color: #fff !important;
    }
  }

  .nav-top>.nav-top__link.sub>a {
    background-color: #fff !important;
    color: @brand-primary !important;
  }

  .nav-top>.nav-top__link--search {
    display: none;
    width: 50px;
    font-size: 21px;
    text-align: center;
    cursor: pointer;
    &.smaller {
      display: inline-block;
      &>a {
        padding: 13px;
      }
    }
  }

  .nav-top__link:first-child {
    margin-left: 0;
  }

  .grey-background {
    display: none;
    left: 0;
    bottom: auto;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 20;
    top: 270px;
    &.smaller {
      top: @brand-background-height-small + 10px !important;
    }
  }

  .navigation-left {
    display: none;
  }

  .megadropdown.search {
    display: none;
    height: 100%;
    width: 134px;
    position: absolute;
    background-color: #fff;
    padding: 0 10px;
    right: 51px;
    top: 0;
    margin-right: -10px;
    left: auto;
    z-index: 1000;
    background-clip: padding-box;
    border-top: none;
  }

  .search__input {
    width: 100%;
    margin-top: 3px;
    &>input {
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 10px;
      width: 100%;
      font-weight: 500;
      font-size: 15px;
      text-transform: uppercase;
    }
  }

  .megadropdown {
    padding: 35px 25px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    display: none;
    border-top: 23px solid transparent;
    background-clip: padding-box;
    border-bottom: 3px solid @brand-primary;
  }

  .megadropdown .row {
    min-height: 248px;
  }

  .dropdown-menu-info {
    padding-top: 10px;
  }

  .megadropdown__headlink {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    //text-transform: uppercase;
    font-size: 13px;
    &::after {
      font-family: 'FontAwesome';
      content: '\f105';
      margin-left: 10px;
      font-weight: bold;
    }
  }


  .megadropdown__einleitung {
    padding-top: 10px;
  }

  .megadropdown__img {
    width: 100%;
    margin: 24px 0 17px;
  }

  .megadropdown__links {
    list-style: none;
    -webkit-padding-start: 30px;
    li {
      margin-top: 10px;
      &:first-child {
        margin: 0;
      }
      &:hover {
        background-color: @brand-primary;
        a {
          margin-left: 15px;
          color: #fff;
          .megadropdown__headlink {
            color: #fff;
          }
        }
      }
      a {
        padding: 10px 20px 10px 0;
        color: @text-grey;
        display: block;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .megadropdown__headlink {
    color: @brand-primary;
    font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  }

  .inhalt {
    position: relative;
    top: -60px;
    margin-bottom: 60px;
  }

  .box {
    margin: 0 0 20px 0;
    position: relative;
  }

  .box__inhalt {
    padding: 12px 18px 10px;
  }

  .box__newstext {
    width: 100%;
    a {
      padding: 5px 0 10px 5px;
    }
  }

  .box__newstext + .box__newstext {
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
  }

  .box__button {
    a {
      h4 {
        margin-bottom: 0;
        &::after {
          font-family: 'FontAwesome';
          content: '\f105';
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
  }

  .box.box--reihe0-3 .box__button {
    height: auto;
    margin: 0 -3px -8px -3px;
  }

  #owl__buergerservice {
    .blue-box__title {
      display: inline-block;
    }
    .text-small {
      height: 40px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .box--reihe0-2 {
    margin: 0 -3px 20px;
    background-color: transparent;
    padding: 0 15px;
  }

  .blue-box__outer {
    padding: 0 3px;
    margin: 3px 0;
  }

  .blue-box__img {
    margin: 0;
  }

  .blue-box--long {
    width: 100%;
  }

  .box--reihe0-3 {
    .box__inhalt {
      padding: 12px 5px 10px;
      .box__img {
        margin: 15px 0;
        width: 100%;
      }
    }
  }

  .owl-navigation {
    position: absolute;
    right: 23px;
  }

  .box__button--small {
    height: 20px;
    width: 20px;
    margin: 0 1px;
    float: left;
    font-size: 14px;
    span {
      margin: 3px 0 0;
    }
    cursor: pointer;
    &:hover {
      background-color: @text-grey;
    }
  }

  .box--reihe3 {
    .box__inhalt {
      padding: 19px 20px 20px;
    }
    .blue-box__outer {
      margin: 17px 0 7px;
      padding: 0 6px;
    }
    .blue-box__img {
      margin: 17px auto;
    }
  }

  .box--reihe4 {
    .box__inhalt {
      padding: 19px 20px 20px;
    }
    hr {
      margin-top: 0px;
      margin-bottom: 15px;
      border: 2px solid @button-grey;
    }
  }

  .veranstaltungen__inhalt {
    margin-top: 8px;
    margin-bottom: 25px;
    font-size: 12px;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .veranstaltungen__inhalt.festeHoehe {
    height: 200px;
  }

  .link-to-bottom {
    position: absolute;
    bottom: 0;
    .veranstaltungen__link {
      color: @brand-primary;
      font-size: 12px;
      &::after {
        font-family: 'FontAwesome';
        content: "\f105";
        margin-left: 10px;
        font-size: inherit;
      }
    }
  }

  .veranstaltungen__datum {
    display: flex;
    align-items: baseline;
    .veranstaltungen__tag {
      font-size: 18px;
    }
    .veranstaltungen__monat {
      margin-left: 5px;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .veranstaltungen__titel {
    font-size: 14px;
    font-family: 'Hybrea W00 Light', Arial, Helvetica, sans-serif;
    min-height: 38px;
    //text-transform: uppercase;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .row--stretch {
    display: flex;
    align-items: stretch;
  }

  .col--stretch {
    display: flex;
  }

  .content-container {
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .left-nav {
    background-color: @background-grey;
    padding: 5px 10px 45px 10px;
    margin-bottom: 20px;
    list-style: none;
    font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    position: relative;
    z-index: 10;
    box-shadow: none;
    width: auto;
    left: 0;
    top: 0;
  }

  .left-nav__dropdown {
    position: relative;
  }

  .left-nav__dropdown.active {
    &>a {
      color: @brand-primary;
      display: block;
      &::after {
        content: "";
        color: @brand-primary;
      }
    }
  }

  .left-nav__dropdown {
    .active {
      color: @brand-primary;
    }
  }

  .left-nav__dropdown.active.sub {
    & > a {
      &::after {
        content: "\f107";
      }
    }
  }

  .left-nav__dropdown.sub {
    & > a {
      color: @brand-primary;
      &::after {
        content: "\f107";
        color: @brand-primary;
      }
    }
  }

  .left-nav__dropdown {
    .left-nav__dropdown-menu {
      display: block;
    }
  }

  .contentsite {
    .box__inhalt {
      padding: 10px 10px 0px 10px;
    }
    .box__img {
      margin: 15px 0 20px;
      width: 100%;
    }
    .veranstaltungen__inhalt {
      margin-bottom: 15px;
    }
    .link-to-bottom {
      margin-bottom: 25px;
      position: relative;
    }
  }

  .veranstaltungen__inhalt {
    margin-top: 15px;
    hr {
      margin-top: 0;
      margin-bottom: 5px;
      border: 2px solid @button-grey;
    }
  }

  .content__jobmesse {
    margin: 40px 0 60px;
    .row {
      background-color: transparent;
      .jobmesse__text {
        h4 {
          color: @text-grey;
          font-size: initial;
        }
      }
    }
  }

  .box--grey {
    background-color: @background-grey;
  }

  .breadcrumb {
    margin-bottom: 0;
    line-height: 1.0;
    font-size: 12px;
    li {
      display: inline-flex;
    }
    a {
      color: @text-grey;
    }
  }

  .breadcrumb>li+li::before {
    font-family: 'FontAwesome';
  }

  .breadcrumb>li+li:before {
    padding: 0 5px 0 5px;
  }

  .content__img {
    width: 50%;
    margin-left: 10px;
  }

  .content__liste {
    margin: 45px 0 60px 20px !important;
  }

  .footer__nav {
    padding: 28px;
  }


  .footer__link {
    height: auto;
    width: 100%;
    background-color: transparent;
    text-align: left;
    margin: 0;
    a {
      height: auto;
      padding: 5px 0;
      position: relative;
      transform: inherit;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer__img {
    width: 100%;
  }

  .footer__social {
    font-size: 12px;
    padding-top: 5px;
  }

  .footer__social .footer__link--social{
    display: inline-flex;
    a {
      margin-right: 50px;
      width: auto;
    }
    a:hover {
      text-decoration: none;
    }
  }

  .form-group.newsletter {
    position: relative;
  }

  .footer__newsletter {
    font-size: 12px;
    p {
      margin: 0 0 5px;
    }
  }

  .newsletter__input {
    height: 30px;
    width: 100%;
    color: @text-grey;
    font-size: 12px;
    padding: 5px 45px 5px 5px;
  }

  .newsletter__button {
    position: absolute;
    display: block;
    top: 2px;
    right: 0;
    width: 32px;
    height: 26px;
    border: 0;
    border-left: 2px solid @button-grey;
    background-color: transparent;
    &::after {
      content: "\f040";
      font-size: 16px;
      color: @text-grey;
    }
  }

  .footer__copyright {
    font-size: 12px;
    text-align: left;
    padding: 10px 0;
  }

  .footer__globals {
    float: right;
    margin-top: -14px;
    a {
      color: #fff;
      width: auto;
      padding: 0 5px;
      margin: 0 5px;
      float: left;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  #schulausfall {
    width: 500px;
    height: 425px;
  }

  #schulausfall_text {
    font-size: 28px;
    height: 385px;
    padding: 140px 20px 0 20px;
    line-height: 32px;
  }

  .tsaid_LayoutSpalte_04 .ytsa_c50l, .tsaid_LayoutSpalte_04 .ytsa_c50r,
  .tsaid_LayoutSpalte_06 .ytsa_c50l, .tsaid_LayoutSpalte_06 .ytsa_c50r,
  .tsaid_LayoutSpalte_11 .ytsa_c50l, .tsaid_LayoutSpalte_11 .ytsa_c50r,
  .tsaid_LayoutSpalte_13 .ytsa_c50l, .tsaid_LayoutSpalte_13 .ytsa_c50r{
    width: 50%!important;
  }
  .tsaid_LayoutSpalte_04 .ytsa_c50r .ytsa_subcr,
  .tsaid_LayoutSpalte_06 .ytsa_c50r .ytsa_subcr,
  .tsaid_LayoutSpalte_11 .ytsa_c50r .ytsa_subcr,
  .tsaid_LayoutSpalte_13 .ytsa_c50r .ytsa_subcr {
    padding: 0 0 0 1em;
  }

  .ytsa_page .ytsa_c80r .ytsa_subcl div#tsaid_NavLeistungskat_02 .tsaid_level1 a {
    width: 205px!important;
  }

  .tsaid_LayoutSpalte_03 .ytsa_c50r ul a {
    margin-right: 5px!important;
  }

  .ytsa_page .ytsa_c80r .ytsa_subcl div#tsaid_NavLeistungskat_02 .tsaid_level1 a {
    width: 250px!important;
  }

  .service-detail__section--top {
    font-size: 19px;
  }

  .service-list__amount {
    font-size: 14px;
  }

  .service-list__view a {
    font-size: 14px;
  }

  a.service-list__item-name {
    font-size: 19px;
  }

  .service-list__item-title {
    flex: unset;
  }

  .create-service .cell-chars .char_num {
    font-size: 12px;
  }

  .service .modal .modal-title {
    font-size: 16px;
  }

  .service-filter__title {
    font-size: 16px;
  }

  .infowindow__head {
    font-size: 14px;
  }

  .btn_del_filter:after,
  .btn_del_filter:before {
    top: 5px;
  }

  .service-filter__links {
    float: right;
  }

  .service-list__view {
    float: right;
  }


}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: @screen-desktop) {

  @brand-height: 75px;
  @brand-background-height: 110px;
  @brand-height-small: 50px;
  @brand-background-height-small: 78px;
  @brand-padding-vertical: (@brand-background-height - @brand-height) /2;
  @brand-padding-vertical-small: (@brand-background-height-small - @brand-height-small) /2;

  .global__button {
    width: 37px;
  }

  body, .box {
    font-size: 14px;
  }

  h1 {
    font-size: 29px;
    //text-transform: uppercase;
  }

  h2 {
    font-size: 17px;
  }

  h3 {
    font-size: 17px;
  }

  h4 {
    font-size: 16px;
  }

  .mceLayout tr td {
    padding: 0!important;
  }

  .container #content table tr td {
    font-size: 14px
  }

  .text-large {
    font-size: 14px;
  }

  .navbar-default {
    height: 425px;
    border-bottom: 5px solid #fff;
  }

  .global {
    height: 40px;
    padding: 0 18px 0 3px;
    a {
      padding: 9px 12px;
      margin: 0 4px;
      .fa {
        font-size: 20px;
      }
    }
  }

  .global a.global__link {
    margin: 3px;
  }

  .global__search .form-group {
    padding: 5px 6px;
    height: 40px;
    margin-bottom: 0;
  }

  .global__input {
    height: 25px;
    width: 187px;
    text-transform: uppercase;
    margin-left: 0;
  }

  .global__button {
    &::after {
      font-size: 20px;
      padding: 5px 7px;
      margin: 0;
    }
  }

  .global__button--submit {
    //display: inline-block;
  }

  .global__button--prov {
    height: 40px;
    //display: none;
  }

  .global__border {
    height: 14px;
    margin: 13px 11px;
  }

  .navbar__background--img {
    height: @brand-background-height;
    &.smaller {
      height: @brand-background-height-small + 30px !important;
      border-bottom: 30px solid #fff;
    }
  }

  .navbar-brand {
    width: 227px;
    height: @brand-background-height;
    &.smaller {
      height: @brand-background-height-small;
      .navbar-brand__img {
        padding: @brand-padding-vertical-small 15px ;
        border-bottom: 5px @brand-primary solid;
        img {
          height: @brand-height-small;
        }
      }
    }
  }

  .navbar-brand__img {
    padding: @brand-padding-vertical 15px;
    img {
      height: 60px;
    }
  }

  .navbar-brand__white {
    right: 220px;
  }

  .navbar-brand.smaller .navbar-brand__white {
    border-bottom: 5px @brand-primary solid;
  }

  .nav-top {
    width: 710px;
  }

  .nav-top__text-size {
    font-size: 18px;
  }

  .nav-top>.nav-top__link{
    width: 140px;
    height: @brand-background-height;
    &.smaller {
      height: @brand-background-height-small;
      &>a {
        padding: 18px 15px;
      }
    }
    &>a {
      padding: 52px 15px;
    }
  }

  .nav-top>.nav-top__link>.active {
    background-color: #fff !important;
    color: @brand-primary !important;
    border-bottom: 5px solid @brand-primary;
  }

  .nav-top>.nav-top__link--search {
    width: 90px;
    font-size: 26px;
    &.smaller>a {
      padding: 26px 5px;
      &.active {
        padding: 22px 5px;
      }
    }
  }

  .megadropdown.search {
    display: none;
    min-height: 100%;
    width: 175px;
    position: absolute;
    background-color: #fff;
    padding: 13px 10px 13px 16px;
    right: 70px;
    top: 0;
    left: auto;
    z-index: 1000;
    background-clip: padding-box;
    border-top: none;
  }

  .megadropdown {
    padding: 35px 25px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    display: none;
    border-top: 16px solid transparent;
    background-clip: padding-box;
    border-bottom: 5px solid @brand-primary;
  }

  .megadropdown .row {
    min-height: 248px;
  }

  .dropdown-menu-info {
    padding-top: 10px;
  }

  .megadropdown__headlink {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    //text-transform: uppercase;
    font-size: 17px;
    &::after {
      font-family: 'FontAwesome';
      content: '\f105';
      margin-left: 10px;
      font-weight: bold;
    }
  }


  .megadropdown__einleitung {
    padding-top: 10px;
  }

  .megadropdown__img {
    width: 100%;
    margin: 24px 0 17px;
  }

  .megadropdown__links {
    list-style: none;
    -webkit-padding-start: 30px;
    li {
      margin-top: 10px;
      &:first-child {
        margin: 0;
      }
      &:hover {
        background-color: @brand-primary;
        a {
          margin-left: 15px;
          color: #fff;
          .megadropdown__headlink {
            color: #fff;
          }
        }
      }
      a {
        padding: 10px 20px 10px 0;
        color: @text-grey;
      }
    }
  }

  .megadropdown__headlink {
    color: @brand-primary;
    font-family: 'Hybrea W00 Regular', Arial, Helvetica, sans-serif;
  }

  .grey-background {
    opacity: 1;
    top: auto;
    &.smaller {
      top: @brand-background-height-small + 30px !important;
    }
  }

  .inhalt {
    top: -100px;
  }

  .box {
    margin: 0 0 30px;
    padding: 5px;
  }

  .box--presse {
    padding: 14px 0 0 0;
    background: #f4f4f4;
    border: 5px #fff solid;
  }

  .box__inhalt {
    padding: 25px 20px 20px;
  }

  .box__datum {
    font-size: 12px;
  }

  .box__button {
    margin: 0 -5px -5px;
  }

  .box.box--reihe0-3 .box__button {
    height: auto;
    margin: 0 -5px -10px -5px;
  }

  .box__button--small {
    margin: 0 1px;
    height: 30px;
    width: 30px;
    font-size: 20px;
    span {
      margin-top: 5px;
    }
  }

  #owl__buergerservice {
    .text-small {
      height: 27px;
    }
  }

  .owl-navigation {
    right: 25px;
  }

  .blue-box__outer {
    padding: 0 5px;
    margin: 5px 0;
  }

  .blue-box__img {
    height: 60px;
  }

  .blue-box__blueback {
    .text-small {
      font-size: 14px;
    }
  }

  .box--reihe0-2 {
    margin: -5px -5px 20px;
    padding: 0 15px;
  }

  .box--reihe0-3 {
    .box__inhalt {
      padding: 12px 14px 10px;
    }
  }

  .box--reihe3 {
    .blue-box__outer {
      padding: 0 15px;
    }
    .blue-box__img {
      margin: 25px auto;
    }
    .box__inhalt {
    }

  }

  .navigation {
    right: 25px;
  }

  .veranstaltungen__datum {
    .veranstaltungen__tag {
      font-size: 25px;
    }
    .veranstaltungen__monat {
      font-size: 16px;
    }
  }

  .contentsite .navbar-default {
    height: 190px;
  }

  .contentsite .grey-background {
    top: auto;
  }

  .content-container {
    padding-top: 30px;
  }

  .left-nav {
    padding: 5px 15px 75px 15px;
    margin-bottom: 30px;
    font-size: 16px;
  }

  .left-nav__dropdown {
    &>a {
      padding: 15px 17px 15px 0;
      &::after {
        top: 14px;
      }
    }
  }

  .left-nav__dropdown-menu>li>a {
    padding: 0 0 13px 0;
    font-family: 'Hybrea W00 Light',Arial,Helvetica,sans-serif;
    font-size: 14px;
  }

  .contentsite {
    .box__inhalt {
      padding: 22px 20px 0px 20px;
    }
    .box__img {
      margin: 20px 0 30px;
    }
  }

  .content__jobmesse {
    margin: 105px 0 135px;
  }

  .content__link {
    margin: 30px 0 60px !important;
  }

  .content__liste {
    margin: 30px 0 40px 20px !important;
    line-height: 1.5;
    li {
      font-weight: 700;
      &::before {
        content: '\f0da';
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        transform: translate(0,0);
        margin-right: 10px;
      }
    }
  }

  .footer__logos {
    padding: 0;
  }

  .footer__img {
    margin-top: 40px;
    margin-right: 8px;
    &:first-child {
      margin-right: 8px;
    }
  }

  .footer__newsletter {
    margin-top: 0;
  }

  .newsletter__input {
    width: 100%;
  }

  .footer__globals {
    a{
      padding: 0 10px;
      margin: 0 6px;
    }
  }

  .tsaid_QueryElementSearchGroupArea .tsaid_searchButton, .tsaid_QueryElementSearchGroup .tsaid_searchButton, .tsaid_QueryElementSearchFormEvent .tsaid_searchButton, .ytsa_page form .tsaid_searchButton{
    position: initial;
    left: 0;
  }

  .tsaid_resetButton{
    position: initial;
    left: 0;
    margin-right: 27px!important;
  }

  .tsaid_LayoutSpalte_03 .ytsa_c50l, .tsaid_LayoutSpalte_03 .ytsa_c50r {
    width:50%!important;
  }

  #tsaid_NavLeistungskat_02 ul {
    margin: 0;
  }

  .ytsa_page .ytsa_c80r .ytsa_subcl div#tsaid_NavLeistungskat_02 .tsaid_level1 a {
    width: 205px!important;
  }

  .tsaid_OrganisationalUnitData .tsaid_label {
    width: 30%!important;
  }

  .tsaid_OrganisationalUnitData .tsaid_data {
    width: 69%!important;
  }

  #tsaid_NavLeistungAZ_01 li {
    width: 80px!important;
    float: left!important;
    padding: 0!important;
  }



  .service-detail__section--top {
    font-size: 20px;
  }

  .service-list__amount {
    font-size: 16px;
  }

  .service-list__view a {
    font-size: 16px;
  }

  a.service-list__item-name {
    font-size: 20px;
  }

  .create-service .cell-chars .char_num {
    font-size: 12px;
  }

  .service .modal .modal-title {
    font-size: 18px;
  }

  .service-filter__title {
    font-size: 18px;
  }

  .infowindow__head {
    font-size: 16px;
  }

  .btn_del_filter:after,
  .btn_del_filter:before {
    top: 7px;
  }

  .service-detail__section {
    display: table;
  }

  .service-detail__section > * {
    display: table-cell;
  }

  .service-detail__field-title {
    width: 250px;
    vertical-align: baseline;
  }

  .service-detail__options {
    text-align: right;
    margin: 0;
  }

  .service-list__item {
    height: 250px;
  }

  .service-list__img {
    width: 280px;
    flex: unset;
    margin-right: 20px;
  }

  .service-list__img img {
    width: unset;
  }

  .service-list__item-info {
    padding: 15px 15px 15px 0;
  }

  .service-list__item-links {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: @screen-lg-desktop) {

  .global a {
    padding: 0 10px;
    margin: 9px 10px;
  }

  .global a.global__link {
    padding: 9px 10px;
  }

  .global {
    padding-left: 3px;
  }

  .global__search .form__group {
    padding: 5px 12px 5px 15px;
  }

  .global__button {
    width: 51px;
  }

  .global__button:after {
    padding: 5px 10px;
  }

  .global__input {
    width: 300px;
  }

  .navbar-brand {
    width: 287px;
    &.smaller {
      padding: 0 15px 0 0;
      .navbar-brand__img {
        padding: @brand-padding-vertical-small 25px;
        border-bottom: 5px @brand-primary solid;
        img {
          height: 63px;
        }
      }
    }
  }

  .navbar-brand__img {
    padding: @brand-padding-vertical 25px;
    img {
      height: 75px;
    }
  }

  .navbar-brand__white {
    right: 287px;
    width: 1448px;
  }

  .nav-top {
    width: 883px;
  }

  .nav-top>.nav-top__link {
    width: 180px;
  }

  .nav-top>.nav-top__link--search {
    width: 103px;
  }

  .nav-top__text-size {
    font-size: 22px;
  }

  .left-nav {
    padding: 5px 25px 75px 25px;
  }

  .megadropdown.search {
    width: 228px;
    right: 80px;
  }

  #tsaid_NavLeistungAZ_01 li {
    width: 100px !important;
  }

  .create-service .form-group {
    display: table;
  }

  .create-service .inline-form-group {
    display: table-row;
    float: left;
    margin-bottom: 0;
  }

  .create-service .cell {
    display: table-cell;
  }

  .create-service .cell-label {
    width: 180px;
    padding: 0 10px 0 0;
  }

  .create-service .inline-form-group:not(:first-child) .cell-label {
    text-align: right;
    width: 140px;
  }

  .create-service .form-group > .cell.cell-input {
    width: 550px;
  }

  .create-service .inline-form-group > .cell.cell-input {
    width: 205px;
  }

  .create-service .inline-form-group > .cell.cell-select {
    min-width: 205px;
    max-width: 550px;
  }

  .create-service .form-group.file-upload > .cell.cell-input {
    width: 390px;
  }

  .create-service .cell-chars {
    width: 95px;
    padding: 0 0 0 5px;
    vertical-align: bottom;
  }

  .create-service .cell.cell-upload-btn {
    vertical-align: top;
    width: 165px;
    padding-left: 10px;
  }

  .service-filter__selectrow {
    display: flex;
  }

  .service-filter__select {
    flex-grow: 1;
    flex-basis: 0;
    margin: 10px;
  }

  .service-filter__select:first-of-type {
    margin-left: 0;
  }

  .service-filter__select:last-of-type {
    margin-right: 0;
  }

  .service-list__item {
    height: 205px;
  }
}

#tinymce p {
  font-size: 14px;
}

.tsaid_nomatchDescription{
  display: none;
}

.values.functions label{
  margin-right:10px;

}

.dropdown-menu li {
  position: static !important;
  padding: 0 !important;
  margin: 0 8px !important;
  list-style: none !important;
}

.dropdown-menu li::before {
  content: '' !important;
}

#breitband--outer {
  position: relative;

  #breitband--loader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 999;
    opacity: 0.5;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

@media print {

  .breadcrumb {
    display: none;
  }

  .hidden-print {
    display: none;
  }

  .col-print-12 {
    width: 100%;
  }

  .service-detail__img img {
    max-width: unset !important;
  }

  .service-detail-box {
    padding: 0;
  }

  footer {
    display: none;
  }
}